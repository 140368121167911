
import React, { useState, useEffect } from 'react';
import STLViewer from 'stl-viewer'
import useWindowDimensions from './useWindowDimensions';



const Home = props => {

    const { height, width } = useWindowDimensions();
    const size = 0.7 * Math.min(width, height)
    return    <div style={{position: 'absolute', 
    mixBlendMode: 'lighten',
    opacity: .8,
    zIndex: 3
    //top: 0
  }} className={props.fade?props.fade : 'fade'}>
    {/* <img src="https://img.currents-andata.xyz/file/currents-images-prod/e476114e63075ccfc4e3bfc89df817de234a66e2.jpg" style={{width: '70vhn'}} /> */}
    <STLViewer
url='./recurLogo.stl'
model='./recurLogo.stl'
width={size}
height={size}
modelColor='#e82755'
backgroundColor='#000000'
rotate={true}
orbitControls={true}
lights={[0.5,0.1,.7]}
rotationSpeeds={[0, props.speed, 0]}
lightColor="#888888"
/>
  </div>
}

export default Home;