



import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Home2 from './Home2';
import HomeCurrents from './HomeCurrents';
import Text from './Text';



function App() {
  
  return (
    <div className="App" style={{position: 'relative'}}>
      <Home />
      <Home2 speed={0.002}/>
      {/* <Home2 fade="fade1" speed={0.0015}/>
      <Home2 fade="fade2" speed={0.001}/> */}
      <HomeCurrents />
   <Text />

    </div>
  );
}

export default App;
