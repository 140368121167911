import React from 'react';
import { useMediaQuery } from 'react-responsive'

const Text = props => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    return <div style={{position: 'absolute', top: 0, width: '100%', height: '100%', color: 'white'}} className='textHolder'>
        {isMobile && <div style={{height: '40vh', minHeight: '40vh'}} />}
<div style={{padding: 16}}>
THE CURRENTS FOUNDATION IS A 501(c)(3) NONPROFIT ORGANIZATION{!isMobile &&<br />} DEDICATED TO BUILDING SYSTEMS FOR CREATIVE RESILIENCE.
</div>
</div>
      
}

export default Text;